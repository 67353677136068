import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { apiAuth } from '../api/api-auth';
import { apiClient } from '../api/api-client';
import { apiGarment } from '../api/api-garment';
import { apiModel } from '../api/api-model';
import { apiPublish } from '../api/api-publish';
import { apiTryon } from '../api/api-tryon';
import { apiUser } from '../api/api-user';
import sessionSliceReducer from './slices/sessionSlice';

const combinedReducer = combineReducers({
    [apiUser.reducerPath]: apiUser.reducer,
    [apiAuth.reducerPath]: apiAuth.reducer,
    [apiClient.reducerPath]: apiClient.reducer,
    [apiGarment.reducerPath]: apiGarment.reducer,
    [apiModel.reducerPath]: apiModel.reducer,
    [apiPublish.reducerPath]: apiPublish.reducer,
    [apiTryon.reducerPath]: apiTryon.reducer,
    session: sessionSliceReducer,
});

// Root reducer that listen to every action dispatched
const rootReducer = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any, action: Action,
) => combinedReducer(action.type === 'logout' ? undefined : state, action);

export const store = configureStore({
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        apiUser.middleware,
        apiAuth.middleware,
        apiClient.middleware,
        apiGarment.middleware,
        apiModel.middleware,
        apiPublish.middleware,
        apiTryon.middleware,
    ),

    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

// Export the typed useSelector hook to use in react component
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
