/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Dress = (props: IconProps) => (
    <Icon viewBox="0 0 26 38" {...props}>
        <path d="m25.4 12.9-1.686-8.425A5.581 5.581 0 0 0 18.256 0h-1.863c-.09.328.29 4.283-3.393 6.522C9.333 4.292 9.69.297 9.607 0H7.745a5.581 5.581 0 0 0-5.459 4.475L.601 12.9c.65.288 1.883.894 4.321 1.56.196.679.285 1.39.285 2.24 0 1.522-.5 2.76-1.032 4.072-.587 1.451-1.194 2.952-1.194 4.908 0 2.35.593 4.178 1.167 5.947.545 1.677 1.06 3.262 1.06 5.26V38h15.585v-1.113c0-1.998.515-3.583 1.06-5.26.573-1.769 1.167-3.597 1.167-5.947 0-1.956-.607-3.457-1.195-4.908-.53-1.312-1.032-2.55-1.032-4.073 0-.848.09-1.56.286-2.238 2.466-.675 3.723-1.297 4.32-1.561Zm-5.666 18.04c-.48 1.479-.974 3-1.122 4.833H7.389c-.149-1.833-.643-3.354-1.123-4.834-.544-1.677-1.059-3.262-1.059-5.26 0-1.522.502-2.76 1.032-4.072.588-1.451 1.195-2.952 1.195-4.908 0-.596-.039-1.15-.118-1.679 1.675.326 3.612.566 5.684.566s4.01-.24 5.684-.566c-.079.529-.117 1.084-.117 1.68 0 1.955.607 3.456 1.194 4.907.531 1.312 1.032 2.55 1.032 4.073 0 1.997-.514 3.582-1.059 5.26ZM13 13.36c-4.41 0-8.24-1.211-9.858-1.807L4.47 4.91a3.35 3.35 0 0 1 3.113-2.68 9.205 9.205 0 0 0 4.92 6.558l.497.249.498-.25a9.206 9.206 0 0 0 4.92-6.558 3.35 3.35 0 0 1 3.113 2.681l1.328 6.643c-1.614.596-5.434 1.805-9.859 1.805Z" fill="currentColor"/>
    </Icon>
);

export default Dress;
