/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Jacket = (props: IconProps) => (
    <Icon viewBox="0 0 38 34" {...props}>
        <path d="M8.983 22.74V34h8.905v-2.267h2.227V34h8.98V22.879l1.687 5.173L38 25.377 30.244 3.64 23.74 0h-9.48L7.834 3.64 0 25.377l7.215 2.673 1.768-5.309Zm20.112-12.406.465-1.974 5.588 15.662-2.975 1.102-3.078-9.44v-5.35ZM24.568 3.4v-.35l3.492 1.953-1.191 5.063v21.667H22.34v-23.8A5.724 5.724 0 0 0 24.568 3.4Zm-8.906-1.133h6.68V3.4c0 2.351-2.095 3.15-2.227 3.273v22.794h-2.226V6.673c-.142-.131-2.227-.915-2.227-3.273V2.267Zm-2.227.794V3.4c0 1.789.84 3.47 2.227 4.533v23.8h-4.453V10.066L10.016 5l3.42-1.938ZM2.86 24.023 8.513 8.338l.47 1.996v5.346l-3.146 9.447-2.978-1.104Z" fill="currentColor"/>
    </Icon>
);

export default Jacket;
