export enum PRODUCTS_CATEGORIES {
    GARMENT = 'garment',
    MODEL = 'model',
    OUTFIT = 'outfit',
}

export enum FILTERS {
    MULTISELECT = 'multi-select',
    RANGESELECT = 'range-select',
}

// LOCALES
export const AUTH_LOCALES = 'auth';
export const COMMON_LOCALES = 'common';
export const DASHBOARD_LOCALES = 'dashboard';
export const ERROR_LOCALES = 'errors';
export const HOME_LOCALES = 'home';
export const LAYOUT_LOCALES = 'layout';
export const PRODUCTS_LOCALES = 'products';
export const PROFILE_LOCALES = 'profile';

export const LOCALES = [
    AUTH_LOCALES,
    COMMON_LOCALES,
    DASHBOARD_LOCALES,
    ERROR_LOCALES,
    HOME_LOCALES,
    LAYOUT_LOCALES,
    PRODUCTS_LOCALES,
    PROFILE_LOCALES,
];

export enum PRODUCT_ACTIONS {
    CREATE_OUTFIT = 'create_outfit',
    PUBLISH = 'publish',
    UNPUBLISH = 'unpublish',
}

export enum PRODUCT_TYPES {
    BOTTOM = 'bottom',
    DRESS = 'dress',
    JACKET = 'jacket',
    OUTERWEAR = 'outerwear',
    MODEL = 'model',
    POSE = 'pose',
    TOP = 'top',
}

// ----- Api fallback -----
export const FALLBACK_API_URL = 'https://app.veesual.ai/api/';

// ----- Action Constant -----
export const LOGOUT_ACTION = { type: 'logout' };

// ----- Storage Constant -----
export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_REFRESH_TOKEN_KEY = 'authRefreshToken';
