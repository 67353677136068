import { RepeatIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Image, ListItem, Spinner, Switch, Text, UnorderedList, useToast, VStack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select, { OnChangeValue } from 'react-select';

import { Bottom, Dress, Jacket, Model, Pose, Top } from '../assets/icons';
import { FilterInContextType, Filters } from '../components/filters/filtersTypes';
import OutfitContext from '../components/products/OutfitContext';
import OutfitTab from '../components/products/OutfitTab';
import ProductsLayout from '../components/products/ProductsLayout';
import { OutfitSelectedProduct } from '../components/products/ProductTypes';
import { useGetGarmentsQuery, useGetGarmentsWithIdMutation } from '../services/api/api-garment';
import { useGetModelsQuery, useGetModelsWithIdMutation } from '../services/api/api-model';
import { useGetTryonMutation } from '../services/api/api-tryon';
import {
    getBottom,
    getClient,
    getConfig,
    getDress,
    getExperience,
    getModel,
    getOuterwear,
    getOutfitGender,
    getOutfitPose,
    getPose,
    getTop,
    hasOutfit,
    setBottom,
    setDress,
    setModel,
    setOuterwear,
    setOutfitGender,
    setOutfitPose,
    setPose,
    setTop,
} from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { ErrorMutation, FacetTerms, FacetTermsData, GarmentQueryInput, TryonQueryInput } from '../types/api-types';
import { calcMaxWidth } from '../utils/calc-max-width';
import { COMMON_LOCALES, DASHBOARD_LOCALES, ERROR_LOCALES, PRODUCT_TYPES, PRODUCTS_CATEGORIES, PRODUCTS_LOCALES } from '../utils/constants';
import resizeImage from '../utils/image';

const customStyles = {
    control: (provided: Record<string, unknown>) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        border: 0,
        width: 125,
    }),
    menu: (provided: Record<string, unknown>) => ({
        ...provided,
        zIndex: 4,
    }),
};

interface SelectOptionType {
    label: string,
    value: string
}

interface ProductDetailsProps {
    label: string;
    productType: PRODUCT_TYPES;
}

const ProductDetails: FC<ProductDetailsProps> = ({ label, productType }) => {
    const { t } = useTranslation([DASHBOARD_LOCALES]);

    return (
        <ListItem whiteSpace="nowrap">
            <HStack textAlign="left" w="full">
                <Trans
                    components={{
                        label: <Text
                            color="gray.700"
                            fontSize="sm"
                            fontWeight="semibold"
                            isTruncated
                            maxW={56}
                        />,
                        productType: <Text color="gray.500" fontSize="sm" fontWeight="medium" w={16} />,
                    }}
                    i18nKey='top_array.label'
                    ns="dashboard"
                    values={{
                        label,
                        productType: t(`product_types.${productType}_one`, { ns: 'products' }),
                    }}
                />
            </HStack>
        </ListItem>
    );
};

const { TOP, BOTTOM, DRESS, OUTERWEAR, MODEL, POSE } = PRODUCT_TYPES;

const Outfit = () => {
    const { t } = useTranslation([PRODUCTS_LOCALES, ERROR_LOCALES, COMMON_LOCALES, DASHBOARD_LOCALES]);

    const poseOptions = [
        { label: t('product_filters.front'), value: 'FRONT' },
        { label: t('product_filters.back'), value: 'BACK' },
    ];
    const genderOptions = [
        { label: t('product_filters.male'), value: 'MALE' },
        { label: t('product_filters.female'), value: 'FEMALE' },
    ];

    const dispatch = useDispatch();

    const toast = useToast();
    const showURLErrorToast = (title?: string) => {
        toast({
            isClosable: true,
            status: 'error',
            title: title || t('url_data', { ns: ERROR_LOCALES }),
        });
    };

    const config = useAppSelector((state) => getConfig(state));

    const hasOutfitInSlice = useAppSelector((state) => hasOutfit(state));

    const [searchParams, setSearchParams] = useSearchParams();

    const productsLayoutRef = React.useRef<{resetData:(keepFilter?: boolean) => void, selectAFilter:(filters: FilterInContextType) => void}>(null);

    const [tabIndex, setTabIndex] = useState<number>(-1);

    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));

    const [getTryon, { data: tryonData, isLoading: isTryonLoading }] = useGetTryonMutation();

    const [lookUrl, setLookUrl] = React.useState<string | null>(null);

    const sliceOutfitPose = useAppSelector((state) => getOutfitPose(state));
    const [localPose, setLocalPose] = React.useState<SelectOptionType>(
        poseOptions.find((option) => option.value === sliceOutfitPose) || poseOptions[0],
    );
    const sliceOutfitGender = useAppSelector((state) => getOutfitGender(state));
    const [localGender, setLocalGender] = React.useState<SelectOptionType>(
        genderOptions.find((option) => option.value === sliceOutfitGender) || genderOptions[0],
    );
    const [lookContainerMaxWidth, setLookContainerMaxWidth] = useState<number>(0);

    const sliceTop = useAppSelector((state) => getTop(state));
    const sliceBottom = useAppSelector((state) => getBottom(state));
    const sliceDress = useAppSelector((state) => getDress(state));
    const sliceOuterwear = useAppSelector((state) => getOuterwear(state));

    const sliceModel = useAppSelector((state) => getModel(state));
    const slicePose = useAppSelector((state) => getPose(state));

    const [topProduct, setTopProduct] = useState<OutfitSelectedProduct | null>(sliceTop);
    const [bottomProduct, setBottomProduct] = useState<OutfitSelectedProduct | null>(sliceBottom);
    const [dressProduct, setDressProduct] = useState<OutfitSelectedProduct | null>(sliceDress);
    const [outerwearProduct, setOuterwearProduct] = useState<OutfitSelectedProduct | null>(sliceOuterwear);

    const [modelProduct, setModelProduct] = useState<OutfitSelectedProduct | null>(sliceModel);
    const [poseProduct, setPoseProduct] = useState<OutfitSelectedProduct | null>(slicePose);

    const [tuckToggle, setTuckToggle] = useState<boolean>(false);

    function handleProductSelect(productKey: string, product: OutfitSelectedProduct | null) {
        const searchClientId = searchParams.get('clientId');
        if (searchClientId) {
            switch (productKey) {
                case TOP:
                    setTopProduct(product);
                    dispatch(setTop(product));
                    break;

                case BOTTOM:
                    dispatch(setBottom(product));
                    setBottomProduct(product);
                    break;

                case DRESS:
                    dispatch(setDress(product));
                    setDressProduct(product);
                    break;

                case OUTERWEAR:
                    dispatch(setOuterwear(product));
                    setOuterwearProduct(product);
                    break;

                case MODEL:
                    dispatch(setModel(product));
                    setModelProduct(product);
                    if (product) {
                        searchParams.set(POSE, product.id);
                    }
                    dispatch(setPose(product));
                    setPoseProduct(product);
                    break;

                case POSE:
                    dispatch(setPose(product));
                    setPoseProduct(product);
                    break;
            }

            if (!product) {
                searchParams.delete(productKey);
            } else {
                searchParams.set(productKey, product.id);
            }
            setSearchParams(searchParams);
        }
    }

    const isMale = localGender.value === 'MALE';

    const tabs = [
        {
            Icon: Top,
            disabledMessage: t('dress_with_a_top', { ns: 'errors' }),
            isDisabled: !!dressProduct,
            label: t('product_types.top_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(TOP, product); },
            selectedProduct: topProduct,
            type: PRODUCT_TYPES.TOP,
        },
        {
            Icon: Bottom,
            disabledMessage: t('dress_with_a_bottom', { ns: 'errors' }),
            isDisabled: !!dressProduct,
            label: t('product_types.bottom_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(BOTTOM, product); },
            selectedProduct: bottomProduct,
            type: PRODUCT_TYPES.BOTTOM,
        },
        {
            Icon: Dress,
            disabledMessage: isMale ? t('male_dress', { ns: 'errors' }) : t('bottom_or_top_with_a_dress', { ns: 'errors' }),
            isDisabled: !!bottomProduct || !!topProduct || isMale,
            label: t('product_types.dress_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(DRESS, product); },
            selectedProduct: dressProduct,
            type: PRODUCT_TYPES.DRESS,
        },
        {
            Icon: Jacket,
            label: t('product_types.outerwear_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(OUTERWEAR, product); },
            selectedProduct: outerwearProduct,
            type: PRODUCT_TYPES.OUTERWEAR,
        },
        {
            Icon: Model,
            label: t('product_types.model_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(MODEL, product); },
            selectedProduct: modelProduct,
            type: PRODUCT_TYPES.MODEL,
        },
        {
            Icon: Pose,
            disabledMessage: t('model_before_his_pose', { ns: 'errors' }),
            isDisabled: !modelProduct,
            label: t('product_types.pose_one'),
            onProductClick: (product: OutfitSelectedProduct | null) => { handleProductSelect(POSE, product); },
            selectedProduct: poseProduct,
            type: PRODUCT_TYPES.POSE,
        },
    ];

    // ----- Garment Functions -----
    const [garmentQueryInputObject, setGarmentQueryInputObject] = React.useState<GarmentQueryInput>(
        {
            clientId: currentClient ? currentClient.id : '',
            experienceId: selectedExperience ? selectedExperience.id : undefined,
        },
    );
    const [getGarmentsWithId, { isLoading: isLoadingGarments }] = useGetGarmentsWithIdMutation();
    const { data: garmentResponse, isFetching: isFetchingGarments } = useGetGarmentsQuery(
        garmentQueryInputObject,
        { skip: tabIndex > 3 || tabIndex === -1 },
    );
    const [productTypes, setProductTypes] = React.useState<FacetTermsData[]>([]);
    const [overrideGarmentFilter, setOverrideGarmentFilter] = React.useState<{filterKey: string, filterValue: string}[] | null>(null);

    function useGarmentQuery(filters: Filters, query = '', page = 1) {
        if (currentClient) {
            const finalFilters = { ...filters };
            if (overrideGarmentFilter) {
                overrideGarmentFilter.forEach((filter) => {
                    if (!finalFilters[filter.filterKey]) {
                        finalFilters[filter.filterKey] = filter.filterValue;
                    }
                });
            }

            setGarmentQueryInputObject({
                clientId: currentClient.id,
                experienceId: selectedExperience?.id,
                filters: finalFilters,
                page,
                query,
            });
        }
    }

    // ----- Model Functions -----
    const [modelQueryInputObject, setModelQueryInputObject] = React.useState<GarmentQueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
    });
    const [getModelsWithId, { isLoading: isLoadingModels }] = useGetModelsWithIdMutation();
    const { data: modelResponse, isFetching: isFetchingModels } = useGetModelsQuery(
        modelQueryInputObject,
        { skip: tabIndex !== -1 && tabIndex !== 4 && tabIndex !== 5 && tabIndex !== tabs.length },
    );
    const [overrideModelFilter, setOverrideModelFilter] = React.useState<{filterKey: string, filterValue: string}[] | null>(null);

    function useModelQuery(filters: Filters, query = '', page = 1, collapse: number | null = null, noIdentityOverride = false) {
        if (currentClient) {
            const finalFilters = filters;
            if (overrideModelFilter) {
                overrideModelFilter.forEach((filter) => {
                    if (!noIdentityOverride || (noIdentityOverride && filter.filterKey !== 'identity_id')) {
                        if (!finalFilters[filter.filterKey]) {
                            finalFilters[filter.filterKey] = [filter.filterValue].concat(finalFilters[filter.filterKey]);
                        }
                    }
                });
            }

            let correctCollapseValue = collapse;
            if (correctCollapseValue === null) {
                correctCollapseValue = (tabIndex === 5 ? 0 : 1);
            }

            setModelQueryInputObject({
                clientId: currentClient.id,
                collapse: correctCollapseValue,
                experienceId: selectedExperience?.id,
                filters: finalFilters,
                page,
                query,
            });
        }
    }

    const handleTabChange = (index: number, customPoseValue?: string, customGenderValue?: string) => {
        if (index !== tabIndex) {
            productsLayoutRef.current?.resetData(false);
            setOverrideModelFilter(null);
            setLookUrl(null);
        }

        let modelId = null;
        if (modelProduct && modelResponse) {
            modelId = modelResponse.items.find((model) => model.model_id === modelProduct.id)?.identity_id;
        }

        // We need to use the customGenderValue because sometimes the state is not yet synchronized
        let genderValue = customGenderValue;
        if (!genderValue) {
            genderValue = localGender.value;
        }

        const fullFilters: Filters = {};

        // We need to use the customPoseValue because sometimes the state is not yet synchronized
        let poseValue = customPoseValue;
        if (!poseValue) {
            poseValue = localPose.value;
        }

        // We separate the handling of Garment calls and Model calls
        if (index < 4) {
            const garmentGenderFilterKey = 'garment_gender';
            const filterGarmentGender = { filterKey: garmentGenderFilterKey, filterValue: genderValue };
            const filterGarmentUnisex = { filterKey: garmentGenderFilterKey, filterValue: 'UNISEX' };
            fullFilters[garmentGenderFilterKey] = [genderValue, 'UNISEX'];

            const garmentPoseFilterKey = 'garment_pose';
            const garmentTypeFilterKey = 'garment_type';

            const filterGarmentPose = { filterKey: garmentPoseFilterKey, filterValue: poseValue };
            fullFilters[garmentPoseFilterKey] = poseValue;

            let garmentTypeFilter = { filterKey: garmentTypeFilterKey, filterValue: 'TOP' };

            switch (index) {
                case 0:
                    garmentTypeFilter = { filterKey: garmentTypeFilterKey, filterValue: 'TOP' };
                    fullFilters[garmentTypeFilterKey] = 'TOP';
                    break;
                case 1:
                    garmentTypeFilter = { filterKey: garmentTypeFilterKey, filterValue: 'BOTTOM' };
                    fullFilters[garmentTypeFilterKey] = 'BOTTOM';
                    break;
                case 2:
                    garmentTypeFilter = { filterKey: garmentTypeFilterKey, filterValue: 'DRESS' };
                    fullFilters[garmentTypeFilterKey] = 'DRESS';
                    break;
                case 3:
                    garmentTypeFilter = { filterKey: garmentTypeFilterKey, filterValue: 'JACKET' };
                    fullFilters[garmentTypeFilterKey] = 'OUTERWEAR';
                    break;
            }

            setOverrideGarmentFilter([
                garmentTypeFilter,
                filterGarmentPose,
                filterGarmentGender,
                filterGarmentUnisex,
            ]);
            useGarmentQuery(fullFilters);
        } else {
            const modelGenderFilterKey = 'gender';
            const filterModelGender = { filterKey: modelGenderFilterKey, filterValue: genderValue };
            fullFilters[modelGenderFilterKey] = [genderValue, 'UNISEX'];

            const modelPoseFilterKey = 'pose';
            const filterModelPose = { filterKey: modelPoseFilterKey, filterValue: poseValue };
            fullFilters[modelPoseFilterKey] = poseValue;
            switch (index) {
                case 4:
                    setOverrideModelFilter([filterModelPose, filterModelGender]);

                    // We add the last parameter of useModelQuery because the overrideModelFilter state is not yet updated so we want
                    // to ignore the identity override
                    useModelQuery(fullFilters, undefined, undefined, 1, true);
                    break;
                case 5:
                    if (modelId) {
                        fullFilters.identity_id = modelId;
                        setOverrideModelFilter([
                            { filterKey: 'identity_id', filterValue: modelId },
                            filterModelPose,
                            filterModelGender,
                        ]);
                        useModelQuery(
                            fullFilters, undefined, undefined, 0,
                        );
                    }
                    break;
            }
        }

        setTabIndex(index);
    };

    const resetAllProductSelect = () => {
        handleProductSelect(TOP, null);
        handleProductSelect(BOTTOM, null);
        handleProductSelect(DRESS, null);
        handleProductSelect(OUTERWEAR, null);
        handleProductSelect(MODEL, null);
        handleProductSelect(POSE, null);
    };

    const handleCreateOutfit = (tryonParams?: TryonQueryInput) => {
        setTabIndex(tabs.length);
        if (currentClient) {
            getTryon(tryonParams || {
                bottom_garment_id: bottomProduct ? bottomProduct.id : '',
                clientId: currentClient.id,
                dress_garment_id: dressProduct ? dressProduct.id : '',
                model_id: poseProduct ? poseProduct.id : '',
                outerwear_garment_id: outerwearProduct ? outerwearProduct.id : '',
                top_garment_id: topProduct ? topProduct.id : '',
            }).unwrap().then((response) => {
                setLookUrl(response.image_urls[0]);
                setTuckToggle(response.mode === 'untuck');
            }).catch((error) => {
                setTabIndex(-1);
                toast({
                    isClosable: true,
                    status: 'error',
                    title: (error as ErrorMutation).data.message,
                });
            });
        }
    };

    function handleTuckToggle(e: React.ChangeEvent<HTMLInputElement>) {
        setLookUrl('');
        setTuckToggle(e.target.checked);
        if (currentClient) {
            handleCreateOutfit({
                bottom_garment_id: bottomProduct ? bottomProduct.id : '',
                clientId: currentClient.id,
                dress_garment_id: dressProduct ? dressProduct.id : '',
                mode: e.target.checked ? 'untuck' : 'tuck',
                model_id: poseProduct ? poseProduct.id : '',
                outerwear_garment_id: outerwearProduct ? outerwearProduct.id : '',
                top_garment_id: topProduct ? topProduct.id : '',
            });
        }
    }

    const resetAllWithoutIndex = () => {
        productsLayoutRef.current?.resetData();
        setOverrideModelFilter(null);
        setOverrideGarmentFilter(null);
        resetAllProductSelect();
    };

    const handleSelectPose = (option: OnChangeValue<SelectOptionType, false>) => {
        if (!option) {
            return;
        }
        const selectValue = option.value;
        // Update Store and local state
        dispatch(setOutfitPose(selectValue));
        setLocalPose(option);

        // Change search params
        searchParams.set('outfit_pose', selectValue);
        setSearchParams(searchParams);

        // Reset data
        resetAllWithoutIndex();

        // If we are in outfit detail index (tabs.length) we reset the index
        handleTabChange(tabIndex === tabs.length || tabIndex === 5 ? -1 : tabIndex, selectValue);
    };

    const handleSelectGender = (option: OnChangeValue<SelectOptionType, false>) => {
        if (!option) {
            return;
        }
        const selectValue = option.value;
        // Update Store and local state
        dispatch(setOutfitGender(selectValue));
        setLocalGender(option);

        // Change search params
        searchParams.set('outfit_gender', selectValue);
        setSearchParams(searchParams);

        // Reset data
        resetAllWithoutIndex();

        // If we are in outfit detail index (tabs.length) we reset the index
        handleTabChange(tabIndex === tabs.length || tabIndex === 5 ? -1 : tabIndex, undefined, selectValue);
    };

    const resetAll = () => {
        setTabIndex(-1);
        resetAllWithoutIndex();
    };

    useEffect(() => {
        if (!modelProduct && poseProduct) {
            setPoseProduct(null);
        }
    }, [modelProduct]);

    useEffect(() => {
        if (productTypes.length === 0 && garmentResponse?.facets.garment_type?.data) {
            setProductTypes((garmentResponse.facets.garment_type as FacetTerms).data);
        }
    }, [garmentResponse]);

    useEffect(() => {
        if (!currentClient) {
            return;
        }

        const outfitPoseFromParam = searchParams.get('outfit_pose');
        if (outfitPoseFromParam) {
            setLocalPose(poseOptions.find((option) => option.value === outfitPoseFromParam) || poseOptions[0]);
        } else {
            searchParams.set('outfit_pose', sliceOutfitPose);
            setSearchParams(searchParams);
        }

        const outfitGenderFromParam = searchParams.get('outfit_gender');
        if (outfitGenderFromParam) {
            setLocalGender(genderOptions.find((option) => option.value === outfitGenderFromParam) || genderOptions[0]);
        } else {
            searchParams.set('outfit_gender', sliceOutfitGender);
            setSearchParams(searchParams);
        }

        // If there are data in the Slice we put them in the URL
        if (hasOutfitInSlice) {
            if (sliceTop) {
                searchParams.set(TOP, sliceTop.id);
            }
            if (sliceBottom) {
                searchParams.set(BOTTOM, sliceBottom.id);
            }
            if (sliceDress) {
                searchParams.set(DRESS, sliceDress.id);
            }
            if (sliceOuterwear) {
                searchParams.set(OUTERWEAR, sliceOuterwear.id);
            }

            if (sliceModel) {
                searchParams.set(MODEL, sliceModel.id);
            }
            if (slicePose) {
                searchParams.set(POSE, slicePose.id);
            }
            setSearchParams(searchParams);

            return;
        }

        // We fetch the data with the IDs we have in the URL only if there is no data in the store already
        const topId = searchParams.get(TOP);
        const bottomId = searchParams.get(BOTTOM);
        const dressId = searchParams.get(DRESS);
        const outerwearId = searchParams.get(OUTERWEAR);
        const modelId = searchParams.get(MODEL);
        const poseId = searchParams.get(POSE);

        const garmentIds = [];
        if (topId) {
            garmentIds.push(topId);
        }

        if (bottomId) {
            garmentIds.push(bottomId);
        }

        if (dressId) {
            garmentIds.push(dressId);
        }

        if (outerwearId) {
            garmentIds.push(outerwearId);
        }

        if (garmentIds.length > 0) {
            getGarmentsWithId({ clientId: currentClient.id, ids: garmentIds }).unwrap()
                .then((response) => {
                    response.items.forEach((item) => {
                        if (item.garment_id === topId) {
                            handleProductSelect(TOP, { id: item.garment_id, name: item.name, url: item.image_clipping_url });
                        }

                        if (item.garment_id === bottomId) {
                            handleProductSelect(BOTTOM, { id: item.garment_id, name: item.name, url: item.image_clipping_url });
                        }

                        if (item.garment_id === dressId) {
                            handleProductSelect(DRESS, { id: item.garment_id, name: item.name, url: item.image_clipping_url });
                        }

                        if (item.garment_id === outerwearId) {
                            handleProductSelect(OUTERWEAR, { id: item.garment_id, name: item.name, url: item.image_clipping_url });
                        }
                    });
                })
                .catch(() => {
                    showURLErrorToast();
                    handleProductSelect(TOP, null);
                    handleProductSelect(BOTTOM, null);
                    handleProductSelect(DRESS, null);
                    handleProductSelect(OUTERWEAR, null);
                });
        }

        const modelIds = [];
        if (modelId) {
            modelIds.push(modelId);
        }

        if (poseId) {
            modelIds.push(poseId);
        }

        if (modelIds.length > 0) {
            getModelsWithId({ clientId: currentClient.id, ids: modelIds }).unwrap()
                .then((response) => {
                    response.items.forEach((item) => {
                        if (item.model_id === modelId) {
                            handleProductSelect(MODEL, { id: item.model_id, name: item.identity_name, url: item.image_url });
                        }

                        if (item.model_id === poseId) {
                            handleProductSelect(POSE, { id: item.model_id, name: item.identity_name, url: item.image_url });
                        }
                    });
                })
                .catch(() => {
                    showURLErrorToast();
                    handleProductSelect(MODEL, null);
                });
        }

        // Automatically generate look if there is enough data
        if (modelId && poseId) {
            if (topId && bottomId) {
                handleCreateOutfit({
                    bottom_garment_id: bottomId,
                    clientId: currentClient.id,
                    model_id: modelId,
                    outerwear_garment_id: outerwearId || '',
                    top_garment_id: topId,
                });
            }

            if (dressId) {
                handleCreateOutfit({
                    clientId: currentClient.id,
                    dress_garment_id: dressId,
                    model_id: modelId,
                    outerwear_garment_id: outerwearId || '',
                });
            }
        }
    }, []);

    useEffect(() => {
        let interval: number;
        function handleResize() {
            const outfitContainer = document.getElementById('outfitPageContainerId');
            if (outfitContainer && config) {
                clearInterval(interval);
                const maxWidth = calcMaxWidth(outfitContainer.clientWidth, 0, outfitContainer.clientHeight, config.look_image_ratio, 136);
                if (maxWidth < outfitContainer.clientWidth) {
                    setLookContainerMaxWidth(maxWidth);
                }
            }
        }
        interval = window.setInterval(() => {
            handleResize();
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    // Need to put it to undefined if tabIndex is -1 so it refresh the grid after reseting the local data inside
    let productsLayoutData = tabIndex > 3 ? modelResponse : garmentResponse;
    if (tabIndex === -1 || tabIndex === tabs.length) {
        productsLayoutData = undefined;
    }

    return (
        <Box boxSize="full" id="outfitPageContainerId" maxH="full">
            <VStack boxSize="full">
                <OutfitContext.Provider value={{
                    onProductClick:
                            tabIndex !== -1 && tabIndex !== tabs.length ? tabs[tabIndex].onProductClick : () => null,
                    selectedProduct:
                            tabIndex !== -1 && tabIndex !== tabs.length ? tabs[tabIndex].selectedProduct : null,
                }}>
                    <ProductsLayout
                        category={tabIndex > 3 ? PRODUCTS_CATEGORIES.MODEL : PRODUCTS_CATEGORIES.GARMENT}
                        clientId={currentClient?.id || ''}
                        customHeader={
                            <VStack w="full">
                                <HStack justify="space-between" w="full">
                                    <HStack spacing={12}>
                                        <HStack spacing={4}>
                                            <VStack spacing={4} textAlign="left" w={125}>
                                                <Select<SelectOptionType>
                                                    defaultValue={localPose}
                                                    getOptionLabel={(option: SelectOptionType) => option.label}
                                                    getOptionValue={(option: SelectOptionType) => option.value}
                                                    onChange={handleSelectPose}
                                                    options={poseOptions}
                                                    styles={customStyles}
                                                    value={localPose}
                                                />
                                                <Select<SelectOptionType>
                                                    getOptionLabel={(option: SelectOptionType) => option.label}
                                                    getOptionValue={(option: SelectOptionType) => option.value}
                                                    onChange={handleSelectGender}
                                                    options={genderOptions}
                                                    styles={customStyles}
                                                    value={localGender}
                                                />
                                            </VStack>
                                            {config && tabs.map((tab, index) => (
                                                (config.garment_types
                                                && (
                                                    (config.garment_types.find((type) => type.toUpperCase() === tab.type.toUpperCase())
                                                    || index > 3
                                                    || config.garment_types.length === 0
                                                    )
                                                ))
                                                && <OutfitTab
                                                    Icon={tab.Icon}
                                                    activeIndex={tabIndex}
                                                    coverOverride={index === 5}
                                                    disabledMessage={
                                                        (isLoadingGarments || isLoadingModels)
                                                            ? t('still_fetching', { ns: ERROR_LOCALES })
                                                            : tab?.disabledMessage}
                                                    index={index}
                                                    isDisabled={tab?.isDisabled || isLoadingGarments || isLoadingModels}
                                                    key={tab.label}
                                                    label={tab.label}
                                                    onClick={handleTabChange}
                                                    removeProduct={() => tab.onProductClick(null)}
                                                    url={tab.selectedProduct ? resizeImage(tab.selectedProduct.url, { width: 800 }) : undefined}
                                                    zoomImage={index === 4}
                                                />
                                            ))}
                                        </HStack>
                                    </HStack>
                                    <HStack>
                                        <Button
                                            isDisabled={
                                                !topProduct && !bottomProduct && !dressProduct && !outerwearProduct && !modelProduct && !poseProduct
                                            }
                                            onClick={resetAll}
                                            variant="white"
                                        >
                                            {t('actions.reset_all')}
                                        </Button>
                                        <Button
                                            isDisabled={!(((topProduct && bottomProduct) || dressProduct) && modelProduct && poseProduct)}
                                            leftIcon={<RepeatIcon boxSize={5} />}
                                            onClick={() => handleCreateOutfit()}
                                            variant="solid"
                                        >
                                            {t('actions.create_outfit')}
                                        </Button>
                                    </HStack>
                                </HStack>
                                {tabIndex === -1 && <Box
                                    alignItems="center"
                                    boxSize="full"
                                >
                                    <Image pb={6} src="/assets/illustrations/outfit-tutorial.svg" />
                                </Box>
                                }
                            </VStack>

                        }
                        dataQuery={tabIndex > 3 ? useModelQuery : useGarmentQuery}
                        hideContent={tabIndex === -1 || tabIndex === tabs.length}
                        isLoading={tabIndex > 3 ? isFetchingModels : isFetchingGarments}
                        productTypes={tabIndex > 3
                            ? []
                            : productTypes}
                        queryResponse={productsLayoutData}
                        ref={productsLayoutRef}
                    />
                </OutfitContext.Provider>
                {
                    tabIndex === tabs.length
                    && <Box boxSize="full" flex={9} marginBottom={10}>
                        {
                            // When look is an empty string it meanse we already loaded once a look so we prevent the full
                            // Spinner when we use the toggle tuck/untuck
                            isTryonLoading && !lookUrl && lookUrl !== ''
                                ? <Box alignItems="center" boxSize="full" display="flex" justifyContent="center">
                                    <Spinner size="xl"/>
                                </Box>
                                : <HStack align="flex-start" boxSize="full" justify="center" spacing={10}>
                                    <Box boxSize="full" position="relative">
                                        <Image
                                            h="auto"
                                            maxW={lookContainerMaxWidth}
                                            position="absolute"
                                            px={10}
                                            right="-150px"
                                            src="/assets/illustrations/blop.svg"
                                            w="full"
                                        />
                                        <VStack
                                            align="flex-end"
                                            boxSize="full"
                                            justifyContent="center"
                                            position="relative"
                                            spacing={6}
                                        >
                                            <Image
                                                border="4px solid white"
                                                borderRadius="2xl"
                                                fallback={
                                                    <Box
                                                        alignItems="center"
                                                        border="4px solid white"
                                                        borderRadius="2xl"
                                                        display="flex"
                                                        h="100%"
                                                        justifyContent="center"
                                                        margin="16px 0"
                                                        w={lookContainerMaxWidth}
                                                    >
                                                        <Spinner />
                                                    </Box>}
                                                h="100%"
                                                marginBottom="16px"
                                                marginTop="16px"
                                                maxW={lookContainerMaxWidth}
                                                objectFit="cover"
                                                onError={() => showURLErrorToast(t('loading_failed', { ns: ERROR_LOCALES }))}
                                                position="inherit"
                                                src={lookUrl ? resizeImage(lookUrl, 800) : ''}
                                                zIndex={3}
                                            />
                                        </VStack>
                                    </Box>
                                    <VStack
                                        align="flex-start"
                                        boxSize="full"
                                        justify="center"
                                        maxH="full"
                                        w="full"
                                        zIndex={2}
                                    >
                                        <VStack align="flex-start" spacing={4} w="full">
                                            <Text
                                                color="gray.400"
                                            >
                                                {t('top_array.content_labels.outfit', { ns: DASHBOARD_LOCALES })}
                                            </Text>
                                            <UnorderedList>
                                                {topProduct
                                            && <ProductDetails label={topProduct.name || topProduct.id} productType={PRODUCT_TYPES.TOP} />}
                                                {bottomProduct
                                            && <ProductDetails label={bottomProduct.name || bottomProduct.id} productType={PRODUCT_TYPES.BOTTOM} />}
                                                {dressProduct
                                            && <ProductDetails label={dressProduct.name || dressProduct.id} productType={PRODUCT_TYPES.DRESS} />}
                                                {modelProduct
                                            && <ProductDetails label={modelProduct.name || modelProduct.id} productType={PRODUCT_TYPES.MODEL} />}
                                                {poseProduct
                                            && <ProductDetails label={poseProduct.name || poseProduct.id} productType={PRODUCT_TYPES.POSE} />}
                                            </UnorderedList>
                                            {
                                                (config && config.enable_tuck)
                                                && <HStack>
                                                    <Text>{t('tuck', { ns: COMMON_LOCALES })}</Text>
                                                    <Switch
                                                        isChecked={tuckToggle}
                                                        isDisabled={tryonData?.mode_switch === false || isTryonLoading}
                                                        onChange={handleTuckToggle}
                                                    />
                                                    <Text>{t('untuck', { ns: COMMON_LOCALES })}</Text>
                                                </HStack>
                                            }

                                            {/* <Button
                                                fontSize="sm"
                                                fontWeight="bold"
                                                variant="outline"
                                            >
                                                {t('save_this_outfit', { ns: COMMON_LOCALES })}
                                            </Button> */}
                                        </VStack>
                                        {/* <VStack align="flex-start" spacing={2} w="full">
                                            <Text
                                                color="gray.700"
                                                fontWeight="semibold"
                                            >
                                                {t('share_your_comment', { ns: COMMON_LOCALES })}
                                            </Text>
                                            <VStack align="flex-start" spacing={6} w="full">
                                                <Textarea bg="white" placeholder={t('type_here', { ns: COMMON_LOCALES })} w="480px" />
                                                <Button
                                                    fontSize="sm"
                                                    fontWeight="bold"
                                                    variant="outline"
                                                >
                                                    {t('share_comment', { ns: COMMON_LOCALES })}
                                                </Button>
                                            </VStack>
                                        </VStack> */}
                                    </VStack>
                                </HStack>
                        }

                    </Box>
                }
            </VStack>
        </Box>
    );
};

export default Outfit;
