import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OutfitSelectedProduct } from '../../../components/products/ProductTypes';
import { ClientConfig, ClientResponse, CustomGroup, Experience, GroupConfig } from '../../../types/api-types';
import { localTokenExist } from '../../../utils/auth-helpers';
import type { RootState } from '../store';

export interface SessionSlice {
    authenticated: boolean,
    client: ClientResponse | null,
    isClientInitialized: boolean, // This is used to know if we finished fetching the clients eventhough there is none
    config: ClientConfig | null,
    top: OutfitSelectedProduct | null,
    bottom: OutfitSelectedProduct | null,
    dress: OutfitSelectedProduct | null,
    experience: Experience | null,
    outerwear: OutfitSelectedProduct | null,
    model: OutfitSelectedProduct | null,
    pose: OutfitSelectedProduct | null,
    outfitPose: string,
    outfitGender: string,
    group: CustomGroup | null
    groupConfig: GroupConfig | null
}

const initialState: SessionSlice = {
    authenticated: localTokenExist(),
    bottom: null,
    client: null,
    config: null,
    dress: null,
    experience: null,
    group: null,
    groupConfig: null,
    isClientInitialized: false,
    model: null,
    outerwear: null,
    outfitGender: 'FEMALE',
    outfitPose: 'FRONT',
    pose: null,
    top: null,
};

// Slice storing the tokens used in the API calls
export const sessionSlice = createSlice({
    initialState,
    name: 'session',
    reducers: {
        resetOutfit: (state: SessionSlice) => {
            state.top = null;
            state.bottom = null;
            state.dress = null;
            state.outerwear = null;
            state.model = null;
            state.pose = null;
        },
        setAuthenticated: (state: SessionSlice, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setBottom: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.bottom = action.payload;
        },
        setClient: (state: SessionSlice, action: PayloadAction<ClientResponse | null>) => {
            state.client = action.payload;
            state.isClientInitialized = true;
        },
        setConfig: (state: SessionSlice, action: PayloadAction<ClientConfig | null>) => {
            state.config = action.payload;
        },
        setDress: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.dress = action.payload;
        },
        setExperience: (state: SessionSlice, action: PayloadAction<Experience | null>) => {
            state.experience = action.payload;
        },
        setGroup: (state: SessionSlice, action: PayloadAction<CustomGroup | null>) => {
            state.group = action.payload;
        },
        setGroupConfig: (state: SessionSlice, action: PayloadAction<GroupConfig | null>) => {
            state.groupConfig = action.payload;
        },
        setModel: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.model = action.payload;
        },
        setOuterwear: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.outerwear = action.payload;
        },
        setOutfitGender: (state: SessionSlice, action: PayloadAction<string>) => {
            state.outfitGender = action.payload;
        },
        setOutfitPose: (state: SessionSlice, action: PayloadAction<string>) => {
            state.outfitPose = action.payload;
        },
        setPose: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.pose = action.payload;
        },
        setTop: (state: SessionSlice, action: PayloadAction<OutfitSelectedProduct | null>) => {
            state.top = action.payload;
        },
    },
});

// ----- Actions -----
export const {
    setAuthenticated, setClient, setConfig, setTop, resetOutfit, setBottom, setDress, setOuterwear, setModel, setPose, setOutfitPose, setOutfitGender,
    setExperience, setGroup, setGroupConfig,
} = sessionSlice.actions;

// ----- Selector -----
export const isAuthenticated = (state: RootState) => state.session.authenticated;
export const hasClient = (state: RootState) => state.session.client !== null;
export const getClient = (state: RootState) => state.session.client;
export const isClientInitialized = (state: RootState) => state.session.isClientInitialized;
export const getConfig = (state: RootState) => state.session.config;
export const getTop = (state: RootState) => state.session.top;
export const getBottom = (state: RootState) => state.session.bottom;
export const getDress = (state: RootState) => state.session.dress;
export const getOuterwear = (state: RootState) => state.session.outerwear;
export const getModel = (state: RootState) => state.session.model;
export const getPose = (state: RootState) => state.session.pose;
export const getOutfitGender = (state: RootState) => state.session.outfitGender;
export const getOutfitPose = (state: RootState) => state.session.outfitPose;
export const hasOutfit = (state: RootState) => !!(state.session.top
    || state.session.bottom || state.session.dress || state.session.outerwear || state.session.model || state.session.pose);
export const getGroup = (state: RootState) => state.session.group;
export const getGroupConfig = (state: RootState) => state.session.groupConfig;
export const getExperience = (state: RootState) => state.session.experience;

export default sessionSlice.reducer;
