import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Icon as ChakraIcon, IconButton, Image, Text, Tooltip } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface OutfitTabProps {
    activeIndex: number;
    disabledMessage?: string;
    Icon: typeof ChakraIcon;
    index: number;
    isDisabled?: boolean;
    label: string;
    onClick: (index: number) => void;
    removeProduct: () => void;
    url?: string;
    zoomImage: boolean;
    coverOverride?: boolean;
}

const OutfitTab: FC<OutfitTabProps> = ({
    activeIndex,
    disabledMessage = undefined,
    Icon,
    index,
    isDisabled = false,
    label,
    onClick,
    removeProduct,
    url = undefined,
    zoomImage,
    coverOverride,
}) => {
    const { t } = useTranslation();

    const objectFitValue = useMemo(() => {
        if (coverOverride) {
            return 'cover';
        }

        if (!zoomImage) {
            return 'contain';
        }

        return 'cover';
    }, [coverOverride, zoomImage]);

    return (
        <Box position="relative">
            <Tooltip hasArrow isDisabled={!isDisabled} label={disabledMessage} placement="bottom-start">
                <Box>
                    <Button
                        isActive={activeIndex === index}
                        isDisabled={isDisabled}
                        onClick={() => onClick(index)}
                        overflow="hidden"
                        p={0}
                        transition={'border 0.1s ease-in-out'}
                        variant="outfit"
                    >
                        {url
                            ? (
                                <Box boxSize="full" minH={24} minW={20} position="relative" textAlign="center">
                                    <Image
                                        h={!zoomImage ? '100%' : 'unset'}
                                        marginLeft={zoomImage ? '-40%' : 0}
                                        minW={zoomImage ? '180%' : '100%'}
                                        objectFit={objectFitValue}
                                        src={url}
                                    />
                                    <Text
                                        backgroundColor="#4B556330"
                                        bottom={0}
                                        color="white"
                                        fontWeight="bold"
                                        position="absolute"
                                        w="full"
                                    >
                                        {t('edit')}
                                    </Text>
                                </Box>
                            )
                            : (
                                <>
                                    <Icon boxSize={9} mb={2} />
                                    <Text fontSize="sm" fontWeight="medium">{label}</Text>
                                </>
                            )}
                    </Button>
                </Box>
            </Tooltip>
            {url && <IconButton
                aria-label='Remove product'
                borderRadius="base"
                boxSize={7}
                icon={<CloseIcon boxSize={3} color={'gray.500'} />}
                minW={0}
                onClick={() => {
                    removeProduct();
                    onClick(index);
                }}
                position="absolute"
                right={-2}
                top={-2}
                variant="white"
            />}
        </Box>
    );
};

export default OutfitTab;
