/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Bottom = (props: IconProps) => (
    <Icon viewBox="0 0 24 38" {...props}>
        <path d="M0 12.246C0 20.065 1.108 30.263 2.347 38h8.51l1.09-22.414h.106L13.143 38h8.532C22.652 30.91 24 19.378 24 12.246V0H0v12.246Zm8.78 23.527H4.206c-.475-3.181-1.973-13.914-2.023-23.067L8.088 6.68h.64v5.566c0 .98.415 1.863 1.077 2.475L8.78 35.773Zm10.996 0H15.22L14.129 13.36H12c-.601 0-1.09-.499-1.09-1.113V6.68h5.002l5.904 6.025c-.061 7.515-1.583 19.6-2.04 23.068Zm2.042-26.215-2.82-2.878h2.82v2.878ZM2.182 2.227h19.636v2.226H2.182V2.227Zm0 4.453h2.82l-2.82 2.878V6.68Z" fill="currentColor"/>
    </Icon>
);

export default Bottom;
