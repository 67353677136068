/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Top = (props: IconProps) => (
    <Icon height="34" viewBox="0 0 38 34" {...props}>
        <path d="m31.25 13.262 1.647 1.139L38 6.983 28.116 0h-5.775v1.133c0 1.875-1.499 3.4-3.341 3.4s-3.34-1.525-3.34-3.4V0H9.883L0 6.983l5.104 7.418 1.646-1.138V34h24.5V13.262Zm-2.227 18.471H8.977V8.983L5.67 11.27 3.107 7.547l7.474-5.28h2.963C14.06 4.85 16.31 6.8 19 6.8c2.689 0 4.939-1.95 5.456-4.533h2.963l7.474 5.28-2.562 3.723-3.308-2.287v22.75Z" fill="currentColor"/>
    </Icon>
);

export default Top;
