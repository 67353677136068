import './i18n/config';
import './theme/style.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';

import { ChakraProvider, Spinner, VStack } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';

import { fakeData, FakeDataContext, FakeDataType } from './fakeData';
import Router from './Router';
import { store } from './services/store/store';
import theme from './theme';

const App = () => (
    <ChakraProvider theme={theme}>
        <React.Suspense fallback={<VStack height='100vh' justifyContent='center' ><Spinner size='xl'/></VStack>}>
            <Provider store={store}>
                <FakeDataContext.Provider value={fakeData as FakeDataType}>
                    <Router />
                </FakeDataContext.Provider>
            </Provider>
        </React.Suspense>

    </ChakraProvider>
);

export default App;
