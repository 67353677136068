import { Image, Link, Text, VStack } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Link as ReachLink, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import AuthLayout from './components/layouts/AuthLayout';
import UnauthLayout from './components/layouts/UnauthLayout';
import { Dashboard, ForgotPassword, Garment, Home, Login, Model, Outfit, Profile } from './pages';
import ResetPassword from './pages/ResetPassword';
import { getClient, getConfig, getExperience, getGroupConfig, isAuthenticated } from './services/store/slices/sessionSlice';
import { useAppSelector } from './services/store/store';
import { localTokenExist, removeTokens } from './utils/auth-helpers';
import useConfigValidation from './utils/config-validation-hook';
import { ERROR_LOCALES } from './utils/constants';

const Router: FC = () => {
    const { t } = useTranslation(ERROR_LOCALES);

    function RequireAuth({ children }: { children: ReactElement }) {
        const location = useLocation();

        // We check if local tokens stil exist and the authenticated boolean in the state so we know if the user logged out from another tab
        const isTokenExist = localTokenExist();
        const isAuth = useAppSelector((state) => isAuthenticated(state));

        if (!isTokenExist && !isAuth) {
            // Theres a loop if only the refreshToken exists so we make sure to delete both
            removeTokens();

            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate
                replace
                to={
                    `/login${location.search === '' ? '?' : location.search}${
                        !location.search.match('from=')
                            ? `${location.search !== '' ? '&' : ''}from=${location.pathname}`
                            : ''}`
                }
            />;
        }

        return children;
    }

    function RequireClient({ children }: { children: ReactElement }) {
        const currentClient = useAppSelector((state) => getClient(state));
        const configNotValid = useConfigValidation();

        if (configNotValid) {
            return <Navigate to={currentClient !== null ? `/?clientId=${currentClient.id}` : '/'} />;
        }

        return children;
    }

    function RequireDashboardEnable({ children }: { children: ReactElement }) {
        const groupConfig = useAppSelector((state) => getGroupConfig(state));
        const config = useAppSelector((state) => getConfig(state));
        const currentClient = useAppSelector((state) => getClient(state));
        const currentExperience = useAppSelector((state) => getExperience(state));

        if (config?.platform_enable_dashboard === false
        || (groupConfig?.platform_enable_dashboard === false && (!config || !config.platform_enable_dashboard))) {
            return <Navigate to={
                currentClient !== null && currentExperience !== null
                    ? `/?clientId=${currentClient.id}&experienceId=${currentExperience.id}`
                    : '/'
            } />;
        }

        return children;
    }

    function RequireOutfitEnable({ children }: { children: ReactElement }) {
        const currentClient = useAppSelector((state) => getClient(state));
        const config = useAppSelector((state) => getConfig(state));
        const location = useLocation();

        if (config?.platform_enable_outfit === false && location.pathname === '/outfit') {
            return <Navigate to={currentClient !== null ? `/?clientId=${currentClient.id}` : '/'} />;
        }

        return children;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route element={
                    <RequireAuth>
                        <AuthLayout />
                    </RequireAuth>
                } path="/">
                    <Route element={<Home />} path="/" />
                    <Route element={<Profile />} path="profile" />

                    {/* Need client to go to those routes */}
                    <Route element={
                        <RequireClient>
                            <Outlet />
                        </RequireClient>
                    }>
                        <Route element={<RequireDashboardEnable><Dashboard /></RequireDashboardEnable>} path="dashboard" />
                        <Route element={<Garment />} path="garment" />
                        <Route element={<Model />} path="model" />
                        <Route element={<RequireOutfitEnable><Outfit /></RequireOutfitEnable>} path="outfit" />
                    </Route>

                    <Route
                        element={
                            <VStack pb={6}>
                                <Image boxSize={96} src="/assets/illustrations/not-found.svg" />
                                <Text>{t('404.message')}</Text>
                                <Link as={ReachLink} fontSize="lg" to="/">{t('404.back_to_home')}</Link>
                            </VStack>
                        }
                        path="*"
                    />
                </Route>
                <Route element={<UnauthLayout />} path="/">
                    <Route element={<Login />} path="login" />
                    <Route element={<ForgotPassword />} path="forgot-password" />
                    <Route element={<ResetPassword />} path="reset-password/:token" />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
