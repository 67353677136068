/**
 * Calculate the max width according to a custom height and width and ratio
 * @param width
 * @param minWidth
 * @param height
 * @param ratio
 * @param heightOffset
 * @returns
 */
export const calcMaxWidth = (width: number, minWidth: number, height: number, ratio: number, heightOffset = 0, marginv = 0, marginh = 0) => {
    const widthFromHeight = (height - 2 * marginh - heightOffset) * ratio + 2 * marginv;

    return Math.max(Math.min(widthFromHeight, width), minWidth);
};

export default calcMaxWidth;
